/**
 * @generated SignedSource<<5f80f30e0594f80fce3ffb78d1eaec05>>
 * @relayHash afae4dce2f7189b3d7773024e861fc87
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-misc/494.0.0-2025-01-15T20:29:17.394Z/sellerDirectoryRootQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sellerDirectoryRootQuery$variables = {
  company?: string | null;
  contentModulePreviewId?: string | null;
  isDistinguished: boolean;
  isTopSeller: boolean;
  page: number;
  verticals?: string | null;
};
export type sellerDirectoryRootQuery$data = {
  readonly viewer: {
    readonly sellers: {
      readonly edges: ReadonlyArray<{
        readonly __typename: "SellersEdge";
      } | null> | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"SellerDirectoryLayout_viewer">;
  };
};
export type sellerDirectoryRootQuery = {
  response: sellerDirectoryRootQuery$data;
  variables: sellerDirectoryRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "company"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentModulePreviewId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isDistinguished"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isTopSeller"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "verticals"
},
v6 = [
  {
    "kind": "Literal",
    "name": "ascDesc",
    "value": "ASC"
  },
  {
    "kind": "Variable",
    "name": "company",
    "variableName": "company"
  },
  {
    "kind": "Literal",
    "name": "exactMatch",
    "value": false
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 60
  },
  {
    "kind": "Variable",
    "name": "isDistinguished",
    "variableName": "isDistinguished"
  },
  {
    "kind": "Literal",
    "name": "isMainStorefrontProfile",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "isTopSeller",
    "variableName": "isTopSeller"
  },
  {
    "kind": "Literal",
    "name": "onlyIncludeLiveStorefrontProfile",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": [
      "COMPANY"
    ]
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Literal",
    "name": "status",
    "value": [
      "ACTIVE",
      "NO_PAY"
    ]
  },
  {
    "kind": "Variable",
    "name": "verticals",
    "variableName": "verticals"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = [
  (v8/*: any*/),
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "sellerDirectoryRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SellerDirectoryLayout_viewer"
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "SellersConnection",
            "kind": "LinkedField",
            "name": "sellers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SellersEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "sellerDirectoryRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "pageId",
                "variableName": "contentModulePreviewId"
              },
              {
                "kind": "Literal",
                "name": "pageName",
                "value": "browse:seller-directory"
              }
            ],
            "concreteType": "SingleBrowseContentModule",
            "kind": "LinkedField",
            "name": "browseContentModule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrowseContentModuleTop",
                "kind": "LinkedField",
                "name": "top",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mainImage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StaticStorefrontBannerImageType",
            "kind": "LinkedField",
            "name": "staticStorefrontBannerImages",
            "plural": true,
            "selections": [
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "SellersConnection",
            "kind": "LinkedField",
            "name": "sellers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numberOfPages",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalResults",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SellersEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Seller",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "serviceId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SellerProfile",
                        "kind": "LinkedField",
                        "name": "sellerProfile",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "company",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SellerPreferences",
                        "kind": "LinkedField",
                        "name": "sellerPreferences",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "urlLabel",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "shippingAddress",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayCityStateCountry",
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StorefrontProfile",
                        "kind": "LinkedField",
                        "name": "storefrontProfiles",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Photo",
                            "kind": "LinkedField",
                            "name": "logo",
                            "plural": false,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Photo",
                            "kind": "LinkedField",
                            "name": "banner",
                            "plural": false,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dealerSince",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDistinguished",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDiamondSeller",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isTopSeller",
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-misc/494.0.0-2025-01-15T20:29:17.394Z/sellerDirectoryRootQuery",
    "metadata": {},
    "name": "sellerDirectoryRootQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b4c8811e4f3a96cd7a77619116a60552";

export default node;
