import { useEffect } from 'react';

type HookOptions = {
    modalId: string;
    isModalOpen: boolean;
    closeModal: () => void;
    openModal: () => void;
    enableHistoryNavigation?: boolean;
};

export function useModalNavigationHandler(options: HookOptions): void {
    const { modalId, isModalOpen, openModal, closeModal, enableHistoryNavigation } = options;

    useEffect(() => {
        function stateListener(event: PopStateEvent): void {
            const isThisModalInState = event.state?.activeModalId === modalId;

            /**
             * Special cases for modals who have navigation handling disabled.
             */
            if (!enableHistoryNavigation) {
                /**
                 * Edge case when interacting with different modals.
                 *
                 * 1. Open modal with navigation handling.
                 * 2. Go back in browser history.
                 * 3. Open a modal that doesn't support navigation handling.
                 * 4. Click forward.
                 *
                 * In this case we want to hide the modal without navigation handling. Otherwise we
                 * would have two modals open.
                 */
                if (isModalOpen && event.state?.activeModalId) {
                    closeModal();
                }
                return;
            }

            if (isThisModalInState) {
                openModal();
            } else if (isModalOpen && !isThisModalInState) {
                closeModal();
            }
        }

        window.addEventListener('popstate', stateListener);

        return () => {
            window.removeEventListener('popstate', stateListener);
        };
    }, [modalId, isModalOpen, openModal, closeModal, enableHistoryNavigation]);

    useEffect(() => {
        const activeModalId = window.history.state?.activeModalId;
        const isThisModalInState = activeModalId === modalId;

        if (!enableHistoryNavigation) {
            return;
        }

        if (isModalOpen && !isThisModalInState) {
            const newState = { activeModalId: modalId };
            /**
             * Replace state if there's already a modal open to prevent unnecessary history entries
             * being added after a user refreshes the page.
             */
            if (activeModalId) {
                window.history.replaceState(newState, '');
            } else {
                window.history.pushState(newState, '');
            }
        } else if (!isModalOpen && isThisModalInState) {
            /**
             * Modal closing, since we've added a history entry for an open modal, just go back
             * to one that doesn't have it.
             */
            window.history.back();
        }
    }, [isModalOpen, modalId, enableHistoryNavigation]);
}
